<template>
  <Row lg="row">
    <Column class="lg:w-3/4">
      <ContentHeader :page-title="title" />

      <div v-show="loading" class="flex items-center space-x-2 text-lg">
        <Icon icon="spinner-third" prefix="fad" spin />
        <span class="text-supplement">Loading...</span>
      </div>

      <div v-show="!loading" ref="results">
        <div class="border-b-2">
          <nav class="-mb-2px flex space-x-8" aria-label="Tabs">
            <a
              @click="setInCollectionFilter('all')"
              class="whitespace-nowrap border-b-2 py-4 px-1 font-medium"
              :class="{
                'border-primary text-primary': query.filters.in_collection === 'all',
                'border-transparent text-paragraph hover:text-gray-700': query.filters.in_collection !== 'all'
              }"
              >All Content</a
            >
            <a
              @click="setInCollectionFilter('in')"
              class="whitespace-nowrap border-b-2 py-4 px-1 font-medium"
              :class="{
                'border-primary text-primary': query.filters.in_collection === 'in',
                'border-transparent text-paragraph hover:text-gray-700': query.filters.in_collection !== 'in'
              }"
              >In Collection</a
            >
            <a
              @click="setInCollectionFilter('out')"
              class="whitespace-nowrap border-b-2 py-4 px-1 font-medium"
              :class="{
                'border-primary text-primary': query.filters.in_collection === 'out',
                'border-transparent text-paragraph hover:text-gray-700': query.filters.in_collection !== 'out'
              }"
              >Not In Collection</a
            >
          </nav>
        </div>
        <Content :items="content" :show-controls="true" v-if="content" />
        <ContentPagination :pagination="pagination" @page-changed="onPageChange" />
      </div>
    </Column>
    <Column class="lg:w-1/4">
      <SidebarWidget title="Add Content">
        <div class="space-y-3">
          <Button :to="{ name: 'content.new' }" type="primary" prepend-icon="content" class="w-full"
            >Add Content</Button
          >

          <!--
              <Button v-if="user.hasPermission('blocks.heading')" type="link" @click="addHeadingBlock()">
                <template v-slot:prepend>
                  <span
                    class="flex h-8 w-12 items-center justify-center rounded border border-l-2 border-l-blocks-heading bg-white text-blocks-heading"
                  >
                    <Icon icon="block-heading" />
                  </span>
                </template>
                <span>Add Heading</span>
              </Button>

              <Button v-if="user.hasPermission('blocks.text')" type="link" @click="addTextBlock()">
                <template v-slot:prepend>
                  <span
                    class="flex h-8 w-12 items-center justify-center rounded border border-l-2 border-l-blocks-text bg-white text-blocks-text"
                  >
                    <Icon icon="block-text" />
                  </span>
                </template>
                <span>Add Text</span>
              </Button>

              <Button v-if="user.hasPermission('blocks.cta')" type="link" @click="addCTABlock()">
                <template v-slot:prepend>
                  <span
                    class="flex h-8 w-12 items-center justify-center rounded border border-l-2 border-l-blocks-cta bg-white text-blocks-cta"
                  >
                    <Icon icon="block-cta" />
                  </span>
                </template>
                <span>Add Call to Action</span>
              </Button>

              <Button v-if="user.hasPermission('blocks.note')" type="link" @click="addNoteBlock()">
                <template v-slot:prepend>
                  <span
                    class="flex h-8 w-12 items-center justify-center rounded border border-l-2 border-l-blocks-note bg-white text-blocks-note"
                  >
                    <Icon icon="block-note" />
                  </span>
                </template>
                <span>Add Note</span>
              </Button>

              <Button v-if="user.hasPermission('blocks.image')" type="link" @click="addImageBlock()">
                <template v-slot:prepend>
                  <span
                    class="flex h-8 w-12 items-center justify-center rounded border border-l-2 border-l-blocks-image bg-white text-blocks-image"
                  >
                    <Icon icon="block-image" />
                  </span>
                </template>
                <span>Add Image</span>
              </Button>

              <Button
                v-if="user.hasPermission('blocks.itinerary') && user.partner.has_premium_itineraries"
                type="link"
                @click="addItineraryBlock()"
              >
                <template v-slot:prepend>
                  <span
                    class="flex h-8 w-12 items-center justify-center rounded border border-l-2 border-l-blocks-itinerary bg-white text-blocks-itinerary"
                  >
                    <Icon icon="block-itinerary" />
                  </span>
                </template>
                <span>Add Itinerary</span>
              </Button>
            -->
        </div>
      </SidebarWidget>

      <SidebarWidget title="Filter Content">
        <InputGroup label="Search">
          <TextInput
            v-model="query.search"
            prepend-icon="search"
            placeholder="Search content"
            @input="search"
            :spellcheck="false"
            :loading="loading"
          />
        </InputGroup>

        <InputGroup label="Content Type">
          <Select
            v-model="query.filters.block_type"
            endpoint="options/block-types"
            prepend-option="All Content"
            @change="search"
            :loading="loading"
          />
        </InputGroup>

        <InputGroup label="Added by">
          <Select
            v-model="query.filters.added_by"
            endpoint="options/content-users"
            prepend-option="All Users"
            @change="search"
            :loading="loading"
          />
        </InputGroup>

        <InputGroup label="Media Type">
          <Select
            v-model="query.filters.type_id"
            endpoint="options/content-types"
            prepend-option="All Media Types"
            @change="search"
            :loading="loading"
          />
        </InputGroup>

        <InputGroup label="Subject Area">
          <Select
            v-model="query.filters.tags"
            endpoint="options/tags"
            prepend-option="All Subject Areas"
            @change="search"
            :loading="loading"
          />
        </InputGroup>

        <InputGroup label="Published">
          <Select
            v-model="query.filters.published"
            endpoint="options/content-published"
            prepend-option="Any"
            @change="search"
            :loading="loading"
          />
        </InputGroup>
      </SidebarWidget>
    </Column>
  </Row>
</template>

<script>
import { mapGetters } from "vuex"
import { debounce, get } from "lodash-es"
import Content from "@/components/Content/Content"
import ContentHeader from "@/components/Content/ContentHeader"
import ContentPagination from "@/components/Content/ContentPagination"
import SidebarWidget from "@/components/SidebarWidget"

const AddCTABlockModal = () => import("@/modals/Blocks/CTA/AddCTABlockModal")
const AddHeadingBlockModal = () => import("@/modals/Blocks/Heading/AddHeadingBlockModal")
const AddImageBlockModal = () => import("@/modals/Blocks/Image/AddImageBlockModal")
const AddItineraryBlockModal = () => import("@/modals/Blocks/Itinerary/AddItineraryBlockModal")
const AddNoteBlockModal = () => import("@/modals/Blocks/Note/AddNoteBlockModal")
const AddTextBlockModal = () => import("@/modals/Blocks/Text/AddTextBlockModal")

export default {
  metaInfo() {
    return { title: this.title }
  },
  components: {
    Content,
    ContentHeader,
    ContentPagination,
    SidebarWidget
  },
  computed: {
    ...mapGetters({
      user: "auth/user"
    })
  },
  data: () => ({
    loading: true,
    title: "Exclusive Content",
    content: [],
    pagination: [],
    query: {
      page: 1,
      search: "",
      filters: {
        block_type: null,
        in_collection: "all",
        destinations: null,
        type_id: null,
        depth: null,
        tags: null,
        published: null,
        added_by: null
      }
    }
  }),
  async created() {
    await this.search()
  },
  methods: {
    search: debounce(async function () {
      this.loading = true
      let { query } = this

      let response = await this.$api.get("blocks/content", {
        params: {
          query: query.search,
          page: query.page,
          filters: query.filters,
          sorting: { created_at: "desc" }
        }
      })

      this.content = get(response, "data.data", [])
      this.pagination = get(response, "data", {})
      this.loading = false
      this.$refs.results.scrollIntoView()
    }, 200),
    onPageChange({ page }) {
      this.query.page = page
      this.search()
    },
    setInCollectionFilter(value) {
      this.query.filters.in_collection = value
      this.search()
    },
    async addCTABlock() {
      let response = await this.$modal.show(AddCTABlockModal)
      console.log("response", response)
      // if (response) this.addBlock(response.data)
    },
    async addHeadingBlock() {
      let response = await this.$modal.show(AddHeadingBlockModal)
      console.log("response", response)
      // if (response) this.addBlock(response.data)
    },
    async addImageBlock() {
      let response = await this.$modal.show(AddImageBlockModal)
      console.log("response", response)
      // if (response) this.addBlock(response.data)
    },
    async addItineraryBlock() {
      let response = await this.$modal.show(AddItineraryBlockModal)
      console.log("response", response)
      // if (response) this.addBlock(response.data)
    },
    async addNoteBlock() {
      let response = await this.$modal.show(AddNoteBlockModal)
      console.log("response", response)
      // if (response) this.addBlock(response.data)
    },
    async addTextBlock() {
      let response = await this.$modal.show(AddTextBlockModal)
      console.log("response", response)
      // if (response) this.addBlock(response.data)
    }
  }
}
</script>
