<template lang="pug">
  div.content-pagination
    p.content-pagination-label(v-html="label")
    nav.content-pagination-links(v-if="last_page > 1")

      a.content-pagination-links-item(@click.prevent="previousPage()", :class="{'is-disabled': disabled || current_page == 1}")
        Icon(icon="chevron-left")
        span.sr-only Previous

      template(v-for="page in pages")
        a.content-pagination-links-item(v-if="page", @click.prevent="changePage(page)", :class="{'is-active': page == current_page, 'is-disabled': disabled}") {{ page }}
        span.content-pagination-links-item.is-disabled(v-if="!page") &hellip;

      a.content-pagination-links-item(@click.prevent="nextPage()", :class="{'is-disabled': disabled || current_page == last_page}")
        span.sr-only Next
        Icon(icon="chevron-right")
</template>

<script>
import { extend } from "lodash-es"

export default {
  props: ["pagination", "disabled"],
  data: () => ({
    paginated: false,
    current_page: 1,
    last_page: 1,
    per_page: 1,
    count: 0,
    chunk_size: 3,
    total: 0
  }),
  watch: {
    pagination(newValue) {
      extend(this, newValue)
    }
  },
  computed: {
    pages() {
      let half_chunk = Math.ceil(this.chunk_size / 2)

      for (var last_page, pages = [], page = 1; page <= this.last_page; page++) {
        let inStartingRange = page + this.chunk_size > this.last_page
        let inEndingRange = page - this.chunk_size < 1
        let inCurrentRange = this.current_page - half_chunk < page && this.current_page + half_chunk > page

        if (inStartingRange || inEndingRange || inCurrentRange) {
          if (page > 1 && last_page != page - 1) {
            pages.push(0)
          }

          last_page = page
          pages.push(page)
        }
      }

      return pages
    },
    label() {
      let total_label = this.$options.filters.number(this.total)

      if (this.count == 1) {
        return "1 item"
      }

      if (this.last_page == 1) {
        return `${total_label} items`
      }

      let from = (this.current_page - 1) * this.per_page + 1,
        to = Math.min(this.current_page * this.per_page, this.total)

      return `${from} &ndash; ${to} of ${total_label} items`
    }
  },
  methods: {
    changePage(page) {
      if (page != this.current_page && page >= 1 && page <= this.last_page) {
        this.current_page = Math.max(1, Math.min(page, this.last_page))
        this.$emit("page-changed", { page: this.current_page })
      }
    },
    previousPage() {
      this.changePage(this.current_page - 1)
    },
    nextPage() {
      this.changePage(this.current_page + 1)
    }
  }
}
</script>

<style>
.content-pagination {
  @apply mt-10 flex items-center justify-between;

  &-links {
    @apply -mr-3 flex flex-wrap;

    &-item {
      @apply block rounded px-3 py-1 text-supplement transition-colors transition-opacity;

      &.is-active {
        @apply bg-primary-default text-paragraph text-white transition-none;
      }

      &.is-disabled {
        @apply cursor-not-allowed opacity-50;

        &:hover {
          @apply text-supplement;
        }
      }
    }
  }
}
</style>
